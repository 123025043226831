<template>
  <b-container fluid class="communication-detail-page mb-6 mt-6">
    <b-overlay :show="loading">
      <b-row>
        <b-col>
          <div class="w-100">
            <!-- Tabs with card integration -->
            <b-card no-body>
              <b-tabs
                nav-wrapper-class="tabs-custom"
                active-nav-item-class="tabs-custom__active_nav-item"
                content-class="tabs-custom__content"
                active-tab-class="pl-0 pr-0"
                v-model="tabIndex"
                card
              >
                <b-tab>
                  <template #title>
                    <span
                      class="status-communication"
                      :class="`status-communication-${form.statusName}`"
                      v-if="form.statusName"
                      >{{ form.statusName }}</span
                    >
                    <div class="d-flex align-items-end">
                      <h5 class="m-0 p-0 mr-3">1</h5>
                      <span>Nội dung thông báo</span>
                    </div>
                  </template>
                  <b-container fluid>
                    <b-row>
                      <b-col cols="8">
                        <b-row>
                          <b-col>
                            <basic-input
                              label="Mã thông báo"
                              placeholder="Mã thông báo"
                              name="code"
                              v-validate="'required|alpha_num'"
                              data-vv-as="Mã"
                              :state="validateState('code')"
                              :invalidFeedback="errors.first('code')"
                              :required="true"
                              :disabled="isEdit"
                              :value.sync="form.code"
                            ></basic-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <basic-input
                              label="Tiêu đề thông báo"
                              placeholder="Nhập tiêu đề thông báo"
                              name="title"
                              v-validate="'required'"
                              data-vv-as="Tiêu đề"
                              maxLength="155"
                              ref="title"
                              :state="validateState('title')"
                              :invalidFeedback="errors.first('title')"
                              :required="true"
                              :value.sync="form.title"
                              :disabled="isDisabled && !isAutomaticNotify"
                            ></basic-input>
                            <Tags
                              :message-type="form.messageType"
                              :is-edit="isEdit"
                              @select="handleInsertToTitle"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <basic-input
                              label="Chủ đề thông báo"
                              placeholder="Nhập chủ đề thông báo sẽ được hiển thị ở màn hình push notification"
                              name="topic"
                              v-validate="'required'"
                              data-vv-as="Chủ đề"
                              ref="topic"
                              maxLength="155"
                              :state="validateState('topic')"
                              :invalidFeedback="errors.first('topic')"
                              :required="true"
                              :value.sync="form.topic"
                              :disabled="isDisabled && !isAutomaticNotify"
                            ></basic-input>

                            <Tags
                              :message-type="form.messageType"
                              :is-edit="isEdit"
                              @select="handleInsertToTopic"
                            />
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col>
                        <div class="picture-input-container">
                          <picture-input
                            ref="pictureInput"
                            width="228"
                            height="128"
                            @change="onChangePicture"
                            margin="16"
                            :prefill="form.image"
                            accept="image/*"
                            size="16"
                            buttonClass="btn btn-success"
                            :crop="false"
                            :removable="true"
                            :customStrings="customStrings"
                          >
                          </picture-input>
                          <p
                            v-show="requiredImage"
                            class="text-center mt-2"
                            style="color: #f64e60"
                          >
                            Hình ảnh là bắt buộc
                          </p>
                          <div class="mt-3 text-center">
                            <span>Hình ảnh liên quan, tỷ lệ 16:9</span>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <basic-text-editors
                          label="Nội dung thông báo"
                          placeholder="Nhập nội dung thông báo"
                          name="content"
                          ref="content"
                          data-vv-as="Nội dung thông báo"
                          v-validate="'required'"
                          :required="true"
                          :state="validateState('content')"
                          :invalidFeedback="errors.first('content')"
                          :value.sync="form.body"
                          :toolbarOptions="['link', 'image', 'video']"
                          :disabled="isDisabled && !isAutomaticNotify"
                        >
                          <!-- <basic-input></basic-input> -->
                        </basic-text-editors>

                        <Tags
                          :message-type="form.messageType"
                          :is-edit="isEdit"
                          @select="handleInsertToContent"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4">
                        <basic-select
                          label="Loại Message"
                          placeholder="--- Chọn loại message ---"
                          name="messageType"
                          :options="messageTypeOpts"
                          :changeValueByObject="true"
                          data-vv-as="Loại Message"
                          v-validate="'required'"
                          :state="validateState('messageType')"
                          :invalidFeedback="errors.first('messageType')"
                          :required="true"
                          :allowEmpty="false"
                          track-by="value"
                          v-model="form.messageType"
                          :value.sync="form.messageType"
                          :disabled="isDisabled || isAutomaticNotify"
                          @input="onSelectMessType"
                        />
                      </b-col>
                      <b-col cols="4">
                        <basic-select
                          label="Loại Redirect"
                          placeholder="--- Chọn loại redirect ---"
                          name="redirectPage"
                          :options="redirectOpts"
                          :changeValueByObject="true"
                          data-vv-as="Loại Redirect"
                          :state="validateState('redirectPage')"
                          :invalidFeedback="errors.first('redirectPage')"
                          track-by="value"
                          :allowEmpty="false"
                          :value.sync="form.redirectType"
                          v-model="form.redirectType"
                          :disabled="
                            isDisabled ||
                            isDisabledRedirectType ||
                            isSurveyMessage
                          "
                        />
                      </b-col>
                      <b-col v-if="isSurveyMessage" cols="4">
                        <basic-select
                          label="Loại khảo sát"
                          placeholder="--- Chọn loại khảo sát ---"
                          name="surveyType"
                          :options="surveys"
                          v-validate="'required'"
                          :changeValueByObject="true"
                          data-vv-as="Loại khảo sát"
                          :state="validateState('surveyType')"
                          :invalidFeedback="errors.first('surveyType')"
                          :required="true"
                          track-by="id"
                          value-label="name"
                          :allowEmpty="false"
                          :value.sync="form.surveyType"
                          v-model="form.surveyType"
                          :disabled="isDisabled"
                          :apiPath="'/Survey'"
                          :searchField="'name'"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <basic-input
                          label="Nội dung link redirect"
                          v-validate="'max:255'"
                          data-vv-as="Nội dung link redirect"
                          :state="validateState('hyperText')"
                          :invalidFeedback="errors.first('hyperText')"
                          placeholder="Nội dung redirect hiển thị trong thông báo chi tiết"
                          name="hyperText"
                          :value.sync="form.hyperText"
                          :disabled="
                            isDisabled ||
                            isDisabledRedirectType ||
                            isSurveyMessage
                          "
                        ></basic-input>
                      </b-col>
                      <b-col>
                        <basic-input
                          label="Web page Link"
                          v-validate="'max:255'"
                          data-vv-as="Web page link"
                          :state="validateState('hyperLink')"
                          :invalidFeedback="errors.first('hyperLink')"
                          placeholder="Nhập web link để redirect"
                          name="hyperLink"
                          :value.sync="form.hyperLink"
                          :disabled="
                            isDisabled ||
                            isDisabledRedirectType ||
                            isSurveyMessage
                          "
                        ></basic-input>
                      </b-col>
                      <b-col></b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4">
                        <basic-radio
                          v-validate="'required'"
                          label="Thời gian gởi thông báo"
                          name="notificationPushType"
                          data-vv-as="Thời gian gởi thông báo"
                          :state="validateState('notificationPushType')"
                          :invalidFeedback="
                            errors.first('notificationPushType')
                          "
                          :labelCols="12"
                          :inline="true"
                          :options="timeOpts"
                          :required="true"
                          :value.sync="form.notificationPushType"
                          :disableRadio="isDisabled || isEdit"
                        >
                        </basic-radio>
                      </b-col>
                      <b-col
                        cols="4"
                        v-if="
                          form.notificationPushType == 2 && !isDisabledDateSend
                        "
                      >
                        <div>
                          <label for="scheduleDate"
                            >Ngày gởi <span class="aterisk">*</span></label
                          >
                          <date-picker
                            placeholder="Nhập ngày gởi"
                            name="scheduleDate"
                            input-class="form-control"
                            type="date"
                            format="DD/MM/YYYY"
                            :default-value="form.scheduleDate"
                            value-type="timestamp"
                            :disabled-date="notBeforeToday"
                            v-validate="'required'"
                            data-vv-as="Ngày gởi"
                            :state="validateState('scheduleDate')"
                            :invalidFeedback="errors.first('scheduleDate')"
                            v-model="form.scheduleDate"
                            :disabled="
                              isDisabled ||
                              isDisabledDateSend ||
                              isAutomaticNotify
                            "
                          >
                          </date-picker>

                          <span
                            class="aterisk"
                            v-show="errors.first('scheduleDate')"
                            >{{ errors.first('scheduleDate') }}</span
                          >
                        </div>
                      </b-col>
                      <b-col cols="4" v-if="form.notificationPushType == 2">
                        <div>
                          <label for="scheduleTime"
                            >Giờ gởi<span class="aterisk">*</span></label
                          >
                          <date-picker
                            placeholder="Nhập giờ gởi"
                            name="scheduleTime"
                            v-validate="'required'"
                            input-class="form-control"
                            type="time"
                            :default-value="
                              $moment(new Date()).add(15, 'minutes')
                            "
                            :disabled-time="notBeforeTimeToday"
                            data-vv-as="Giờ gởi"
                            value-type="timestamp"
                            format="HH:mm"
                            v-model="form.scheduleTime"
                            :disabled="isDisabled"
                          >
                            <template v-slot:icon-calendar>
                              <span class="svg-icon">
                                <inline-svg
                                  src="/media/svg/icons/Neolex/Time/icon.svg"
                                />
                              </span>
                            </template>
                          </date-picker>

                          <span
                            class="aterisk"
                            v-show="errors.first('scheduleTime')"
                            >{{ errors.first('scheduleTime') }}</span
                          >
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-tab>
                <!-- :disabled="isDisabledTab" -->
                <b-tab>
                  <template #title>
                    <div
                      class="d-flex align-items-end justify-content-between w-100"
                    >
                      <h5 class="m-0 p-0 mr-3">2</h5>
                      <span>Đối tượng nhận thông báo</span>
                    </div>
                  </template>
                  <b-container fluid>
                    <b-row>
                      <b-col>
                        <h6>Thiết lập đối tượng bệnh nhân nhận thông báo</h6>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <basic-radio
                          name="notificationObject"
                          :labelCols="12"
                          :inline="false"
                          :options="notificationObjectOpts"
                          :required="true"
                          :value.sync="patientReceive.notificationPushTarget"
                        >
                        </basic-radio>
                      </b-col>
                    </b-row>
                    <template
                      v-if="patientReceive.notificationPushTarget === 2"
                    >
                      <b-row>
                        <b-col>
                          <h6>Chọn các app users</h6>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <div class="w-100 d-flex justify-content-between">
                            <h6>
                              {{
                                patientChoosens.length
                                  ? patientChoosens.length
                                  : 'Chưa có'
                              }}
                              bệnh nhân được nhận thông báo
                            </h6>

                            <b-button @click.stop="handleAddUser">
                              <span class="svg-icon">
                                <inline-svg
                                  src="/media/svg/icons/Neolex/Basic/plus.svg"
                                /> </span
                              >Thêm app users</b-button
                            >
                          </div>
                        </b-col>
                        <!-- <basic-select
                          placeholder="Chọn app users"
                          name="appUsers"
                          apiPath="/Patient"
                          :searchField="'name'"
                          valueLabel="fullName"
                          taggable
                          track-by="id"
                          :multiple="true"
                          changeValueByObject
                          :value.sync="patientChoosens"
                        /> -->
                      </b-row>
                      <b-row>
                        <b-col>
                          <template-table
                            :column="column"
                            :data="patientChoosens"
                            :tableAction="false"
                            :selectAction="false"
                            :searchAction="false"
                            :pagingAction="false"
                          >
                            <template v-slot:body="{ item }">
                              <td>
                                <b-button
                                  variant="link"
                                  @click.stop="handleRemovePatient(item)"
                                >
                                  <span class="svg-icon">
                                    <inline-svg
                                      style="width: 2rem; height: 2rem"
                                      src="/media/svg/icons/Neolex/Basic/close.svg"
                                    />
                                  </span>
                                </b-button>
                              </td>
                              <td>
                                <div class="d-flex align-items-center mt-5">
                                  <avatar
                                    v-if="item"
                                    size="40px"
                                    :text="item.fullName"
                                    :src="item.imageUrl && item.imageUrl.url"
                                    :rounded="true"
                                  ></avatar>
                                  <div class="d-flex flex-column ml-5">
                                    <p
                                      class="mb-0"
                                      style="
                                        font-weight: 600;
                                        font-size: 13px;
                                        color: #515356;
                                      "
                                    >
                                      {{ item.fullName }}
                                    </p>
                                    <p
                                      class="mt-2 mb-0"
                                      style="font-size: 12px; color: #888c9f"
                                    >
                                      Mã số: {{ item.code || 0
                                      }}{{
                                        item.membershipType
                                          ? `, ${item.membershipType}`
                                          : ', Thành viên Cơ Bản'
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {{ item.age }}
                              </td>
                              <td>
                                {{ item.gender }}
                              </td>
                              <td>
                                {{
                                  $moment(item.createDatetime * 1000).format(
                                    'DD/MM/YYYY',
                                  )
                                }}
                              </td>
                              <td>
                                {{ item.nation && item.nation.name }}
                              </td>
                              <td>
                                {{ item.province && item.province.name }}
                              </td>
                              <td>
                                {{ item.district && item.district.name }}
                              </td>
                              <td>
                                {{ item.ward && item.ward.name }}
                              </td>
                              <td>
                                <div style="max-width: 100px">
                                  <user-state :isActive="item.active" />
                                </div>
                              </td>
                            </template>
                          </template-table>
                        </b-col>
                      </b-row>
                    </template>

                    <template
                      v-if="patientReceive.notificationPushTarget === 4"
                    >
                      <b-overlay :show="loadingBtn">
                        <b-row>
                          <b-col>
                            <h6>Thiết lập điều kiện nhận thông báo</h6>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <label for="age">Độ tuổi</label>
                            <b-row>
                              <b-col>
                                <basic-input
                                  name="startAge"
                                  inputType="number"
                                  v-validate="'numeric|max_value:200'"
                                  data-vv-as="Tuổi bắt đầu"
                                  :state="validateState('startAge')"
                                  :invalidFeedback="errors.first('startAge')"
                                  :value.sync="patientReceive.ageStart"
                                ></basic-input>
                              </b-col>
                              <b-col>
                                <basic-input
                                  name="endAge"
                                  inputType="number"
                                  v-validate="'numeric|max_value:200'"
                                  data-vv-as="Tuổi kết thúc"
                                  :state="validateState('endAge')"
                                  :invalidFeedback="errors.first('endAge')"
                                  :value.sync="patientReceive.ageEnd"
                                ></basic-input>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col>
                            <basic-select
                              label="Giới tính"
                              placeholder="--- Chọn giới tính ---"
                              name="gender"
                              :options="isGenderOpts"
                              :solid="false"
                              changeValueByObject
                              :value.sync="patientReceive.gender"
                              :allowEmpty="true"
                            />
                          </b-col>
                          <b-col>
                            <basic-select
                              label="Tình trạng bệnh"
                              placeholder="--- Chọn tình trạng bệnh ---"
                              name="condition"
                              :options="diabetesStateOptions"
                              changeValueByObject
                              :value.sync="patientReceive.diabetesStatus"
                              :solid="false"
                              :allowEmpty="true"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <basic-select
                              label="Tỉnh thành"
                              placeholder="--- Chọn tỉnh thành ---"
                              name="province"
                              :apiPath="'/Division/Provinces'"
                              :searchField="'searchTerm'"
                              :solid="false"
                              changeValueByObject
                              :value.sync="patientReceive.provinceId"
                              :allowEmpty="true"
                            />
                          </b-col>
                          <b-col>
                            <basic-select
                              label="Quận / huyện"
                              placeholder="--- Chọn quận / huyện ---"
                              name="district"
                              :apiPath="'/Division/Dictricts'"
                              :searchField="'searchTerm'"
                              :searchParams="{
                                provinceId: provinceSearch,
                              }"
                              :solid="false"
                              changeValueByObject
                              :value.sync="patientReceive.districtId"
                              :allowEmpty="true"
                            />
                          </b-col>
                          <b-col>
                            <basic-select
                              label="Phường xã"
                              placeholder="--- Chọn phường xã ---"
                              name="wards"
                              :apiPath="'/Division/Wards'"
                              changeValueByObject
                              :searchParams="{
                                districtId: districtSearch,
                              }"
                              :searchField="'searchTerm'"
                              :value.sync="patientReceive.wardId"
                              :solid="false"
                              :allowEmpty="true"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <basic-select
                              :value.sync="patientReceive.coaches"
                              :allowEmpty="true"
                              :solid="false"
                              name="coach"
                              label="Nhóm Coach"
                              placeholder="--- Chọn nhóm coach ---"
                              changeValueByObject
                              :options="coacheList"
                              multiple
                            />
                          </b-col>
                          <b-col>
                            <basic-select
                              :value.sync="patientReceive.clinics"
                              :allowEmpty="true"
                              :solid="false"
                              label="Bệnh viện / Phòng khám"
                              placeholder="--- Chọn bệnh viện / phòng khám ---"
                              name="clinics"
                              changeValueByObject
                              multiple
                              :options="clinicList"
                            />
                          </b-col>
                          <b-col>
                            <label for="">BMI</label>
                            <b-row>
                              <b-col>
                                <basic-select
                                  placeholder="Từ"
                                  name="bmiFrom"
                                  :solid="false"
                                  :allowEmpty="true"
                                  :state="validateState('bmiFrom')"
                                  :invalidFeedback="errors.first('bmiFrom')"
                                  value-label="text"
                                  track-by="value"
                                  :options="bmiFromOpts"
                                  data-vv-as="BMI từ"
                                  changeValueByObject
                                  :value.sync="patientReceive.bmi.from"
                                  @update:value="selectBmi($event, 'from')"
                                />
                              </b-col>
                              <b-col>
                                <basic-select
                                  label=""
                                  placeholder="Đến"
                                  name="bmiTo"
                                  :solid="false"
                                  :allowEmpty="true"
                                  :state="validateState('bmiTo')"
                                  :invalidFeedback="errors.first('bmiTo')"
                                  value-label="text"
                                  track-by="value"
                                  :options="bmiToOpts"
                                  changeValueByObject
                                  data-vv-as="BMI đến"
                                  :value.sync="patientReceive.bmi.to"
                                  @update:value="selectBmi($event, 'to')"
                                />
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="4">
                            <basic-select
                              :value.sync="patientReceive.interestTopics"
                              :allowEmpty="true"
                              :solid="false"
                              name="module"
                              label="Chủ đề quan tâm"
                              placeholder="--- Chọn chủ đề quan tâm ---"
                              changeValueByObject
                              multiple
                              :options="interestTopicList"
                            />
                          </b-col>
                          <b-col cols="4">
                            <basic-select
                              :value.sync="patientReceive.packages"
                              :allowEmpty="true"
                              :solid="false"
                              name="package"
                              label="Gói hiện tại"
                              placeholder="--- Chọn gói hiện tại ---"
                              changeValueByObject
                              multiple
                              :options="packageList"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <div class="w-100 d-flex justify-content-between">
                              <h6>
                                {{ paging.total ? paging.total : 'Chưa có' }}
                                bệnh nhân thoả điều kiện thông báo
                              </h6>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <template-table
                              :column="column"
                              :paging="paging"
                              :data="patientQualities"
                              :tableAction="false"
                              :selectAction="false"
                              :searchAction="false"
                            >
                              <template v-slot:body="{ item }">
                                <td></td>
                                <td>
                                  <div class="d-flex align-items-center mt-5">
                                    <avatar
                                      v-if="item"
                                      size="40px"
                                      :text="item.fullName"
                                      :src="item.imageUrl && item.imageUrl.url"
                                      :rounded="true"
                                    ></avatar>
                                    <div class="d-flex flex-column ml-5">
                                      <p
                                        class="mb-0"
                                        style="
                                          font-weight: 600;
                                          font-size: 13px;
                                          color: #515356;
                                        "
                                      >
                                        {{ item.fullName }}
                                      </p>
                                      <p
                                        class="mt-2 mb-0"
                                        style="font-size: 12px; color: #888c9f"
                                      >
                                        Mã số: {{ item.code || 0
                                        }}{{
                                          item.membershipType
                                            ? `, ${item.membershipType}`
                                            : ', Thành viên Cơ Bản'
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {{ item.age }}
                                </td>
                                <td>
                                  {{ item.gender }}
                                </td>
                                <td>
                                  {{
                                    $moment(item.createDatetime * 1000).format(
                                      'DD/MM/YYYY',
                                    )
                                  }}
                                </td>
                                <td>
                                  {{ item.nation && item.nation.name }}
                                </td>
                                <td>
                                  {{ item.province && item.province.name }}
                                </td>
                                <td>
                                  {{ item.district && item.district.name }}
                                </td>
                                <td>
                                  {{ item.ward && item.ward.name }}
                                </td>
                                <td>
                                  <div style="max-width: 100px">
                                    <user-state :isActive="item.active" />
                                  </div>
                                </td>
                              </template>
                            </template-table>
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                  </b-container>
                </b-tab>
              </b-tabs>

              <template #footer>
                <div class="d-flex align-items-center justify-content-center">
                  <b-button
                    class="btn ml-2"
                    type="button"
                    @click="backTab"
                    v-if="tabIndex === 1"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/arrow-left.svg"
                      />
                    </span>
                    Trở về trước
                  </b-button>
                  <b-button
                    class="btn ml-2"
                    type="button"
                    v-if="showBtnSave"
                    @click="resetRequest"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                      />
                    </span>
                    Reset dữ liệu
                  </b-button>
                  <template v-if="!isAutomaticNotify">
                    <b-button
                      v-if="isEdit && form.statusName == 'Active'"
                      class="btn ml-2 btn-inactive btn-secondary"
                      type="button"
                      @click="handleUpdateStatus(0)"
                    >
                      <span class="svg-icon">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/power.svg"
                        />
                      </span>
                      Inactive
                    </b-button>
                    <b-button
                      class="btn btn-primary btn-secondary ml-2"
                      type="button"
                      @click.prevent="handleActiveNotification(form)"
                      v-if="isEdit && status && form.statusName == 'Draft'"
                    >
                      <span class="svg-icon">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/play.svg"
                        />
                      </span>
                      Kích hoạt thông báo
                    </b-button>
                    <b-button
                      class="btn btn-secondary btn-inactive ml-2"
                      type="button"
                      @click.prevent="handleUpdateStatus(3)"
                      v-if="isEdit && status && form.statusName == 'Sent'"
                    >
                      <span class="svg-icon">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Music/player-pause-circle.svg"
                        />
                      </span>
                      Thu hồi thông báo
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button
                      v-if="isEdit && form.status == 1"
                      class="btn ml-2 btn-inactive btn-secondary"
                      type="button"
                      @click="showPopupConfirm(4)"
                    >
                      <span class="svg-icon">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/power.svg"
                        />
                      </span>
                      Inactive
                    </b-button>
                    <b-button
                      class="btn btn-primary btn-secondary ml-2"
                      type="button"
                      @click="showPopupConfirm(1)"
                      v-if="isEdit && form.status == 4"
                    >
                      <span class="svg-icon">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/play.svg"
                        />
                      </span>
                      Active
                    </b-button>
                  </template>
                  <b-button
                    class="btn btn-success ml-2"
                    type="button"
                    @click.prevent="nextTab"
                    v-if="tabIndex === 0"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/arrow-right.svg"
                      />
                    </span>
                    Lưu và tiếp tục
                  </b-button>
                  <b-button
                    class="btn ml-2"
                    type="button"
                    @click.prevent="handleCheckUser(true)"
                    v-if="
                      tabIndex === 1 &&
                      patientReceive.notificationPushTarget == 4
                    "
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/save.svg"
                      />
                    </span>
                    <span> Kiểm tra số users thoả điều kiện</span>
                  </b-button>
                  <b-button
                    class="btn ml-2"
                    type="button"
                    @click.prevent="handleSubmit(null)"
                    v-if="tabIndex === 1 && showBtnSave && !isAutomaticNotify"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/save.svg"
                      />
                    </span>
                    Lưu nháp
                  </b-button>
                  <b-button
                    class="btn btn-success ml-2"
                    type="button"
                    @click.prevent="handleSubmit(1)"
                    v-if="tabIndex === 1 && showBtnSave"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/save.svg"
                      />
                    </span>
                    Lưu thông báo
                  </b-button>
                </div>
              </template>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
    <add-user
      :patientChoosens="patientChoosens"
      @updatePatient="updatePatient"
    />
  </b-container>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import PictureInput from 'vue-picture-input';
import ModalAddUser from './modalAddUser';
import {
  communicationMixin,
  NOTI_MESS_TYPE,
  NOTI_STATUS_TYPE,
  SEND_NOTI_TIME,
} from './mixins/communicationForm';

export default {
  name: 'CommunicationForm',

  mixins: [communicationMixin],

  components: {
    PictureInput,
    'date-picker': DatePicker,
    'add-user': ModalAddUser,
    Tags: () => import('./components/tags'),
  },

  data() {
    return {
      // Some data is from ./mixins/communicationForm.js
      tabIndex: 0,
      file: null,

      // Tab - Noti content
      form: {
        code: null,
        title: '',
        topic: '',
        image: null,
        body: '',
        hyperText: null,
        hyperLink: null,
        redirectPage: null,
        redirectType: null,
        messageType: null,
        notificationPushType: null,
        scheduleDate: this.convertDateToTimestamp(new Date()) * 1000,
        scheduleTime: this.$moment(new Date()).add(15, 'minutes'),
        surveyType: null,
      },

      // Tab - Noti receiver
      patientReceive: {
        communicationId: null,
        notificationPushTarget: 1,
        ageStart: null,
        ageEnd: null,
        gender: null,
        diabetesStatus: null,
        nationId: null,
        provinceId: null,
        districtId: null,
        wardId: null,

        coaches: [],
        clinics: [],
        interestTopics: [],
        packages: [],
        topic: null,
        bmi: {
          from: {
            text: `0`,
            value: `0`,
          },
          to: {
            text: `0`,
            value: `0`,
          },
        },
      },
      patientChoosens: [],
      patientQualities: [],
      surveys: [],
      interestTopicList: [],
      clinicList: [],
      coacheList: [],
      packageList: [],

      // Common checking
      isEdit: false,
      isCopy: false,
      status: null,
      loadingBtn: false,
      isDisabledTab: true,
      show: false,
      loading: false,
      requiredImage: false,

      // Pagination
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },

  watch: {
    tabIndex(newVal, oldVal) {
      if (newVal == 0 && oldVal == 1) {
        this.isDisabledTab = true;
      }
      if (
        newVal == 1 &&
        !this.coacheList.length &&
        !this.clinicList.length &&
        !this.interestTopicList.length &&
        !this.packageList.length
      )
        this.getDropdownList();
    },

    paging: {
      handler() {
        this.handleCheckUser();
      },
      deep: true,
    },
  },

  computed: {
    provinceSearch() {
      return this.patientReceive?.provinceId?.id;
    },

    districtSearch() {
      return this.patientReceive?.districtId?.id;
    },

    isDisabled() {
      return (
        this.isEdit == true &&
        (this.form.statusName === 'Sent' || this.form.statusName === 'Recall')
      );
    },

    isDisabledRedirectType() {
      const messtype = this.form.messageType?.value;
      return [
        NOTI_MESS_TYPE.JOIN_SERVICE_SUCCESS,
        NOTI_MESS_TYPE.JOIN_GROUP,
        NOTI_MESS_TYPE.TARGET_START_DAY,
        NOTI_MESS_TYPE.TARGET_END_DAY,
        NOTI_MESS_TYPE.TARGET_START_WEEK,
        NOTI_MESS_TYPE.TARGET_END_WEEK,
        NOTI_MESS_TYPE.COACH_APPT_30_MINUTE,
        NOTI_MESS_TYPE.COACH_APPT_1_DAY,
      ].includes(messtype);
    },

    isDisabledDateSend() {
      return [
        NOTI_MESS_TYPE.HAPPY_BIRTHDAY,
        NOTI_MESS_TYPE.TARGET_START_DAY,
        NOTI_MESS_TYPE.TARGET_END_DAY,
        NOTI_MESS_TYPE.TARGET_START_WEEK,
        NOTI_MESS_TYPE.TARGET_END_WEEK,
      ].includes(this.form?.messageType?.value);
    },

    showBtnSave() {
      if (this.isEdit == false) {
        return true;
      } else {
        return (
          this.form.statusName !== 'Sent' && this.form.statusName !== 'Recall'
        );
      }
    },

    customStrings: {
      get() {
        return {
          drag: `<div style="
            height: 28px;
            width: 28px;
            background-color: #fff;
            box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
            margin: auto;"
            class="d-flex align-items-center justify-content-center rounded-circle">
            <span>
            <img src="/media/svg/icons/Neolex/Basic/edit-2.svg" alt="Edit Icon" class="svg-icon" width="14px" height="14px" />
            </span>
            </div>`,
          change: 'Đổi hình ảnh',
          remove: 'Xóa hình ảnh',
        };
      },
    },

    isAutomaticNotify() {
      return (
        this.form.messageType &&
        ![
          NOTI_MESS_TYPE.MARKETING,
          NOTI_MESS_TYPE.ACTIVITY,
          NOTI_MESS_TYPE.SURVEY,
        ].includes(this.form.messageType.value)
      );
    },
    isSurveyMessage() {
      return (
        this.form.messageType &&
        this.form.messageType.value === NOTI_MESS_TYPE.SURVEY
      );
    },
  },

  methods: {
    // Some functions is from ./mixins/communicationForm.js
    async handleCheckUser(isToast = false) {
      try {
        this.loadingBtn = true;

        const params = this.getCheckUserParams(
          this.patientReceive,
          this.paging,
        );
        const res = await this.$api.get('Communication/CountPatientTarget', {
          params,
        });

        this.patientQualities = res.data;
        this.paging.total = res.meta.total;
        isToast && this.toastCheckUser(res.meta.total);
      } catch (error) {
        this.toastFailedMessage(error.message);
      } finally {
        this.loadingBtn = false;
      }
    },

    handleRemovePatient(patient) {
      const index = this.patientChoosens.indexOf(patient);
      this.patientChoosens.splice(index, 1);
    },

    updatePatient(payload) {
      this.patientChoosens.push(payload);
    },

    handleAddUser() {
      this.$bvModal.show('add-user-modal');
    },
    handleInsertToTitle(insertText) {
      if (!insertText.length || this.isDisabled) return;
      const textarea = this.$refs.title;
      const sentence = textarea.value;
      const len = sentence.length;

      let pos = textarea.$children[0]?.$children[0]?.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }

      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);

      this.form.title = before + insertText + after;

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length;
      });
    },

    handleInsertToTopic(insertText) {
      if (!insertText.length || this.isDisabled) return;
      const textarea = this.$refs.topic;
      const sentence = textarea.value;
      const len = sentence.length;

      let pos = textarea.$children[0]?.$children[0]?.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }

      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);

      this.form.topic = before + insertText + after;

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length;
      });
    },
    async handleInsertToContent(insertText) {
      if (!insertText.length || this.isDisabled) return;

      const textarea = this.$refs.content;

      let pos = await textarea.editor.getSelection(true);
      this.$refs.content.editor.insertText(pos.index, insertText);
    },
    async handleActiveNotification() {
      this.loading = true;
      try {
        if (this.form.notificationPushType === 1) {
          await this.handleUpdateStatus(1);
        } else {
          await this.handleUpdateStatus(1);
        }
      } catch (error) {
        this.toastFailedMessage(error.message);
      } finally {
        this.loading = false;
      }
    },

    async handleUpdateStatus(payload) {
      this.loading = true;
      try {
        let formData = {
          id: this.form.id,
          status: payload,
        };
        await this.$api.put('Communication/UpdateStatus', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        await this.loadData();
        this.$toastr.s('Cập nhật thành công', 'Thành công !');
      } catch (error) {
        this.toastFailedMessage(error.message);
      } finally {
        this.loading = false;
      }
    },
    async changeStatus(status) {
      this.loading = true;
      try {
        let params = {
          id: this.form.id,
          status,
        };
        const { meta, error } = await this.$api.put(
          'Communication/ChangeStatus',
          params,
        );
        if (!meta.success) {
          return this.toastFailedMessage(error.message);
        }
        await this.loadData();
        this.$toastr.s('Cập nhật thành công', 'Thành công !');
      } catch (error) {
        this.toastFailedMessage(error.message);
      } finally {
        this.loading = false;
      }
    },
    async handleInactive(item) {
      this.loading = true;
      try {
        await this.$api.delete(`Communication/Input/${item.id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        await this.loadData();
        this.$toastr.s('Cập nhật thành công', 'Thành công !');
      } catch (error) {
        this.toastFailedMessage(error.message);
      } finally {
        this.loading = false;
      }
    },
    getNameStatus(status) {
      return status === NOTI_STATUS_TYPE.ACTIVE ? 'active' : 'inactive';
    },
    showPopupConfirm(state) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái ${
          this.form.code
        } thành ${this.getNameStatus(state)} không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(state);
          }
        }.bind(this),
      );
    },

    async handleSubmit(status = null) {
      let result = await this.$validator.validateAll();
      this.requiredImage = this.form.image === null;
      if (!result || this.requiredImage) {
        if (this.tabIndex === 1) {
          this.tabIndex = 0;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 200);
          return;
        }
        return;
      }
      this.loading = true;
      const payload = {
        code: this.form.code,
        title: this.form.title,
        topic: this.form.topic,
        body: this.form.body,
      };

      this.isEdit && (payload['id'] = this.form.id);
      const conditionSend = this.patientReceive.notificationPushTarget;

      this.form.messageType &&
        (payload['messageType'] = this.form.messageType.value);
      this.form.redirectType &&
        (payload['redirectType'] = this.form.redirectType.value);
      this.form.notificationPushType &&
        (payload['notificationPushType'] = this.form.notificationPushType);
      this.form.hyperText && (payload['hyperText'] = this.form.hyperText);
      this.form.hyperLink && (payload['hyperLink'] = this.form.hyperLink);

      if (this.isSurveyMessage) {
        this.form.surveyType && (payload['surveyId'] = this.form.surveyType.id);
      }

      if (this.form.notificationPushType == 2) {
        this.form.scheduleDate &&
          (payload['scheduleDate'] = this.form.scheduleDate / 1000);

        this.form.scheduleTime &&
          (payload['scheduleTime'] = Math.floor(this.form.scheduleTime / 1000));
      }
      this.patientReceive.notificationPushTarget &&
        (payload['notificationPushTarget'] =
          this.patientReceive.notificationPushTarget);
      if (conditionSend == 4) {
        payload['patientReceive'] = {
          appPatientFilter: {},
        };

        this.patientReceive.ageStart &&
          (payload['patientReceive'].appPatientFilter.ageStart =
            this.patientReceive.ageStart);

        this.patientReceive.ageEnd &&
          (payload['patientReceive'].appPatientFilter.ageEnd =
            this.patientReceive.ageEnd);

        this.patientReceive.gender &&
          (payload['patientReceive'].appPatientFilter.gender =
            this.patientReceive.gender.id);

        this.patientReceive.diabetesStatus &&
          (payload['patientReceive'].appPatientFilter.diabetesStatus =
            this.patientReceive.diabetesStatus.id);

        this.patientReceive.provinceId &&
          (payload['patientReceive'].appPatientFilter.provinceId =
            this.patientReceive.provinceId.id);

        this.patientReceive.districtId &&
          (payload['patientReceive'].appPatientFilter.districtId =
            this.patientReceive.districtId.id);

        this.patientReceive.wardId &&
          (payload['patientReceive'].appPatientFilter.wardId =
            this.patientReceive.wardId.id);

        this.patientReceive.bmi.from?.value &&
          (payload['patientReceive'].appPatientFilter.bmiStart =
            this.patientReceive.bmi.from.value);
        this.patientReceive.bmi.to?.value &&
          (payload['patientReceive'].appPatientFilter.bmiEnd =
            this.patientReceive.bmi.to.value);

        this.patientReceive.coaches?.length &&
          (payload['patientReceive'].appPatientFilter.trainingGroupIds =
            this.patientReceive.coaches.map((el) => el.id));
        this.patientReceive.clinics?.length &&
          (payload['patientReceive'].appPatientFilter.hospitalIds =
            this.patientReceive.clinics.map((el) => el.id));
        this.patientReceive.interestTopics?.length &&
          (payload['patientReceive'].appPatientFilter.interestTopicIds =
            this.patientReceive.interestTopics.map((el) => el.id));
        this.patientReceive.packages?.length &&
          (payload['patientReceive'].appPatientFilter.packageIds =
            this.patientReceive.packages.map((el) => el.id));
      }

      if (conditionSend == 2) {
        payload['patientReceive'] = {};

        payload['patientReceive'].patientChoosens = this.patientChoosens.map(
          (el) => ({ id: el.id }),
        );
      }

      if (this.isCopy) {
        payload['imageId'] = this.form.imageUrl?.id;
      } else {
        // formData.append('image', this.form.image);
        payload['imageId'] = this.file
          ? await this.uploadImage(this.file)
          : this.form.imageUrl?.id;
      }
      if (this.$route.params.form_type == 'edit') {
        status !== null
          ? (payload['status'] = status)
          : (payload['status'] = this.form.status);
        try {
          await this.$api.put(`Communication/Input`, payload);
          this.$toastr.s('Cập nhật thành công', 'Thành công');

          this.$router.push({ path: '/communication' });
        } catch (error) {
          this.toastFailedMessage(error.message);
        } finally {
          this.loading = false;
        }
      } else {
        try {
          status && (payload['status'] = status);
          await this.$api.post(`Communication/Input`, payload);
          this.$toastr.s('Tạo mới thành công', 'Thành công');
          this.$router.push({ path: '/communication' });
        } catch (error) {
          this.toastFailedMessage(error.message);
        } finally {
          this.loading = false;
        }
      }
    },
    async uploadImage(file) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_IMAGE',
        file,
      );
      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
          preventDuplicates: true,
        });
        return null;
      }
      return data.id;
    },
    onChangePicture() {
      this.file = this.$refs.pictureInput.file;
      this.form.image = this.$refs.pictureInput.file;
    },
    nextTab() {
      this.requiredImage = this.form.image === null;
      this.$validator.validateAll().then((result) => {
        if (!result || this.requiredImage) {
          this.isDisabledTab = true;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 200);
          return;
        } else {
          this.$nextTick(() => {
            this.isDisabledTab = false;
          });

          setTimeout(() => {
            this.tabIndex = 1;
          });
        }
      });
    },
    backTab() {
      this.tabIndex = 0;
    },
    resetRequest() {
      // Reset Form Data
      if (!this.isAutomaticNotify) {
        this.form.code = null;
        this.form.hyperText = null;
        this.form.hyperLink = null;
        this.form.redirectPage = null;
        this.form.messageType = null;
        this.form.notificationPushType = null;
        this.form.scheduleDate = this.convertDateToTimestamp(new Date()) * 1000;
        this.form.scheduleTime = this.$moment(new Date()).add(15, 'minutes');
      }
      this.form.title = null;
      this.form.topic = '';
      this.form.body = '';
      this.form.image = null;

      this.patientReceive.communicationId = null;
      this.patientReceive.notificationPushTarget = 1;
      this.patientReceive.ageStart = null;
      this.patientReceive.ageEnd = null;
      this.patientReceive.gender = null;
      this.patientReceive.diabetesStatus = null;
      this.patientReceive.nationId = null;
      this.patientReceive.provinceId = null;
      this.patientReceive.districtId = null;
      this.patientReceive.wardId = null;
      this.patientChoosens = [];
      this.patientQualities = [];
      this.patientReceive.clinics = [];
      this.patientReceive.coaches = [];
      this.patientReceive.interestTopics = [];
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    async loadData() {
      this.loading = true;
      try {
        let { data } = await this.$api.get(
          `Communication/${this.$route.params.id}`,
        );
        this.form = data;
        this.form.scheduleDate = data.scheduleDate
          ? data.scheduleDate * 1000
          : this.convertDateToTimestamp(new Date()) * 1000;
        this.form.scheduleTime = data.scheduleTime && data.scheduleTime * 1000;
        this.patientReceive.notificationPushTarget =
          data.notificationPushTarget;
        this.form.image = data.imageUrl?.url;
        this.form.messageType = this.messageTypeOpts.find(
          (e) => e.value == data.messageType,
        );
        this.status = this.statusOpts.find((e) => e.value == data.status);
        this.patientChoosens = data.patientReceives;
        data.bmiStart &&
          (this.patientReceive.bmi.from = this.bmiOpts.find(
            (e) => e.value == data.bmiStart,
          ));
        data.bmiEnd &&
          (this.patientReceive.bmi.to = this.bmiOpts.find(
            (e) => e.value == data.bmiEnd,
          ));
        this.patientReceive.ageStart = data.ageStart;
        this.patientReceive.ageEnd = data.ageEnd;
        this.patientReceive.diabetesStatus = this.diabetesStateOptions.find(
          (e) => e.id == data.diabetesStatus,
        );
        this.patientReceive.gender = this.isGenderOpts.find(
          (e) => e.id == data.gender,
        );
        this.patientReceive.coaches =
          data.trainingGroups?.map((el) => ({
            name: el.name,
            id: el.trainingGroupId,
          })) || [];
        this.patientReceive.clinics =
          data.hospitals?.map((el) => ({ name: el.name, id: el.hospitalId })) ||
          [];
        this.patientReceive.interestTopics =
          data.lessonModules?.map((el) => ({
            name: el.name,
            id: el.lessonModuleId,
          })) || [];
        this.patientReceive.packages =
          data.packages?.map((el) => ({ name: el.name, id: el.packageId })) ||
          [];

        this.patientReceive.provinceId = data.province.name && data.province;
        this.patientReceive.districtId = data.district.name && data.district;
        this.patientReceive.wardId = data.ward.name && data.ward;
        this.form.redirectType = this.redirectOpts.find(
          (e) => e.value == data.redirectType,
        );
        this.form.surveyType = data.survey;
      } catch (error) {
        this.toastFailedMessage(error.message);
      } finally {
        this.loading = false;
      }
    },

    onSelectMessType({ value }) {
      switch (value) {
        case NOTI_MESS_TYPE.CREATE_ACCOUNT:
        case NOTI_MESS_TYPE.JOIN_SERVICE_SUCCESS:
        case NOTI_MESS_TYPE.JOIN_GROUP:
        case NOTI_MESS_TYPE.COACH_APPT_30_MINUTE:
        case NOTI_MESS_TYPE.COACH_APPT_1_DAY:
          this.form.notificationPushType = SEND_NOTI_TIME.NOW;
          break;

        case NOTI_MESS_TYPE.HAPPY_BIRTHDAY:
        case NOTI_MESS_TYPE.TARGET_START_DAY:
        case NOTI_MESS_TYPE.TARGET_END_DAY:
        case NOTI_MESS_TYPE.TARGET_START_WEEK:
        case NOTI_MESS_TYPE.TARGET_END_WEEK:
          this.form.notificationPushType = SEND_NOTI_TIME.SCHEDULE;
          break;

        default:
          break;
      }
    },
    selectBmi(event, type) {
      if (!this.patientReceive.bmi.to && !this.patientReceive.bmi.from) {
        this.bmiFromOpts = this.bmiOpts;
        return (this.bmiToOpts = this.bmiOpts);
      }

      if (type === 'from') {
        if (
          this.patientReceive.bmi.to &&
          this.patientReceive.bmi.to.value == 0
        ) {
          this.patientReceive.bmi.to = null;
        }

        return (this.bmiToOpts = this.bmiOpts.filter(
          (el) => Number(el.value) > Number(event.value),
        ));
      }

      if (
        this.patientReceive.bmi.from &&
        this.patientReceive.bmi.from.value == 0
      ) {
        this.patientReceive.bmi.from = null;
      }

      return (this.bmiFromOpts = this.bmiOpts.filter(
        (el) => Number(el.value) < Number(event.value),
      ));
    },
    async getDropdownList() {
      try {
        this.loading = true;
        const { data, meta, error } = await this.$api.get(
          '/Communication/CommunicationExtend',
        );

        if (!meta.success) {
          return this.toastFailedMessage(error.message);
        }

        this.coacheList = data.trainingGroups;
        this.clinicList = data.hospitals;
        this.interestTopicList = data.lessonModules;
        this.packageList = data.packages;
      } catch (error) {
        this.toastFailedMessage(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.pictureInput.onResize();
    }, 100);
  },
  created() {
    this.isEdit = this.$route.params.form_type == 'edit';
    this.isCopy = this.$route.params.form_type == 'copy';
    if (this.$route.params.id) {
      this.loadData();
    }
    !this.isEdit && (this.form.notificationPushType = 1);
  },
};
</script>

<style lang="scss">
.swal-icon--success__hide-corners {
  background-color: transparent;
}
.swal-icon--success:after.swal-icon--success:before {
  background-color: transparent;
}
.communication-detail-page {
  .tabs-custom {
    .nav-tabs {
      margin-right: -0.125rem;
      margin-bottom: -2rem;
      margin-left: -0.125rem;
      border-bottom: 0;

      .nav-item {
        .nav-link {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          transition: none;

          h5,
          span {
            color: #888c9f;
          }
        }
        .nav-link:hover,
        .nav-link:focus {
          border: none;
          border-bottom: 3px solid #4bb2ab;
          h5,
          span {
            color: #008479;
          }
        }

        .nav-link.tabs-custom__active_nav-item {
          border: none;
          border-bottom: 3px solid #4bb2ab;
          h5,
          span {
            color: #008479;
          }
        }
      }
    }
  }

  .picture-input-container {
    .picture-input {
      .preview-container {
        background: #ffffff;
        border: 3px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        .picture-preview {
          z-index: 100 !important;
          background-color: #f3f6f9 !important;
        }

        .picture-inner {
          border: none;
        }
      }
    }
  }
}
.aterisk {
  color: #f64e60;
  font-size: 0.9rem;
}
</style>
<style lang="scss" scoped>
.status-communication {
  position: absolute;
  right: 27px;
  border-radius: 56px;
  padding: 2px 14px;
  color: #fff !important;
  &-Draft {
    background-color: #888c9f;
  }
  &-Active {
    background-color: #407bff;
  }
  &-Sent {
    background-color: #21a567;
  }
  &-Recall {
    background-color: #ff5756;
  }
}
.btn-inactive {
  color: #ffffff !important;
  background-color: #ff5756 !important;
  border: none !important;
}
</style>
